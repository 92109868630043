
import Vue from 'vue';
import { mapState } from 'vuex';
import debounce from 'lodash.debounce';
import { OutputSlide } from '@/shared/legacy/classes';
import { PitchAgency } from '@/shared/types';

import { Services } from '@/injectables/tokens';
import { OutputServiceContract } from '@/injectables';
import { Routes } from '@/router/routes';

import OutputEditToolbar from '@/components/Output/OutputEditToolbar.vue';
import OutputSidebar from '@/components/Output/OutputSidebar.vue';
import AddSlideMenu from '@/components/Output/AddSlideMenu.vue';

import OutputContainer from '@/entities/output/output-container.vue';

import ToggleSideBarButton from '@/features/output/toggle-side-bar-button.vue';
import OutputMainActionsBtn from '@/features/output/output-main-actions.vue';
import ActiveSlideContainer from '@/features/output/active-slide-container.vue';

import { SlideVisibility } from '@/app/graphql';

export default Vue.extend({
  name: 'ProposalOutput',

  components: {
    OutputEditToolbar,
    OutputSidebar,
    OutputContainer,
    ToggleSideBarButton,
    OutputMainActionsBtn,
    AddSlideMenu,
    ActiveSlideContainer,
  },

  inject: ['$confirm'],

  useInjectable: [Services.Output],

  data: (): {
    showSidebar: boolean;
    agency: string;
    sidebarWidth: number;
    toolbarHeight: number;
  } => ({
    showSidebar: true,
    agency: '',
    sidebarWidth: 360,
    toolbarHeight: 64,
  }),

  computed: {
    ...mapState('output', ['activeSlideId']),
    activeProposal() {
      return this.$store.state.newProposal.newProposal;
    },
    activeSlideIndex(): number {
      const index = this.slides.findIndex((slide: OutputSlide) => slide._id === this.activeSlideId);

      return index !== -1 ? index : 0;
    },
    activeSlide(): OutputSlide | undefined {
      return this.slides[this.activeSlideIndex];
    },
    sidebarToggleStyle(): string {
      return `left: ${this.showSidebar ? this.sidebarWidth + 5 : 10}px; top: ${
        this.toolbarHeight + 11
      }px; position: fixed`;
    },
    agencyPropertyId(): string {
      return this.selectedAgency.PropertyId;
    },
    allAgencies(): PitchAgency[] {
      return this.$store.state.client.allAgencies?.list || [];
    },
    isSmallerScreen(): boolean {
      return this.$vuetify.breakpoint.width <= 1600;
    },
    outputLoading(): boolean {
      return this.$store.state.output.outputLoading;
    },
    hasUnsavedChanges(): boolean {
      return this.$store.state.output.outputHasBeenChanged;
    },
    activeSlideIsReadOnly(): boolean {
      return this.activeSlide?.readonly || false;
    },
    isChangeDisabled(): boolean {
      return (
        this.$store.state.newProposal.newProposal?.status &&
        (this.outputService as OutputServiceContract).proposalOutputIsChangeDisabled({
          proposalStatus: this.$store.state.newProposal.newProposal.status,
        })
      );
    },
    isAdmin(): boolean {
      return this.$store.getters['auth/isAdmin'];
    },
    isAgencyAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    productId(): string {
      const { productId = '' } = this.$route.params;
      return productId;
    },
    routeParamAgency(): string {
      const { agency = '' } = this.$route.params;
      return agency;
    },
    defaultFont(): string {
      return (this.outputService as OutputServiceContract).defaultOutputFontFamily(this.userAgency);
    },
    selectedAgency(): PitchAgency {
      const name = this.agency || this.$store.state.output?.activeOutput?.agency || this.userAgency;
      return { PropertyId: name, name };
    },
    userAgency(): string {
      return this.$store.state.auth.user.Agency;
    },
    loading(): boolean {
      return this.$store.state.output.getOutputLoading || this.$store.state.output.outputLoading;
    },
    slides(): OutputSlide[] {
      return this.$store.getters['output/allLocalSlides'].filter(
        slide => slide?.visibility !== SlideVisibility.Deleted,
      );
    },
    proposalId() {
      const { proposalId } = this.$route.params;
      return proposalId;
    },
  },

  methods: {
    async resetOutput(): Promise<void> {
      const { confirmed } = await this.$confirm.show({
        title: 'Reset output',
        body: 'Are you sure that you want to revert all changes made to this output?',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
      });

      if (!confirmed) return;

      try {
        const result = await this.$store.dispatch('output/resetOutput', { proposalId: this.proposalId });

        if (result) {
          await this.getOutputSlides(this.proposalId);

          this.$store.dispatch('showSnackbar', {
            content: 'Output has been reset successfully',
            color: 'success',
          });
        }
      } catch (err) {
        this.$store.dispatch('showSnackbar', {
          content: err,
          color: 'error',
        });
      }
    },
    slideIdByIndex(slideIndex: number): string {
      const foundSlide = this.slides[slideIndex];

      return foundSlide._id;
    },
    debouncedOnWheel: debounce(function (e: WheelEvent): void {
      e.preventDefault();

      let newSlideId = '';

      if (e.deltaY < 0 && this.activeSlideIndex !== 0) {
        newSlideId = this.slideIdByIndex(this.activeSlideIndex - 1);
      } else if (e.deltaY > 0 && this.activeSlideIndex !== this.slides.length - 1) {
        newSlideId = this.slideIdByIndex(this.activeSlideIndex + 1);
      }

      newSlideId && this.$store.dispatch('output/updateActiveSlide', newSlideId);
    }, 80),
    saveOutput(): void {
      this.$store.dispatch('output/saveOutput');
    },
    async closeConfirmation() {
      return (
        await this.$confirm.show({
          title: 'Unsaved changes',
          body: 'Are you sure you want to leave without saving?',
          confirmText: 'Leave',
          cancelText: 'Stay',
        })
      ).confirmed;
    },
    async handleCloseOutputEditor(): Promise<void> {
      if (this.hasUnsavedChanges && !(await this.closeConfirmation())) {
        return;
      }
      if (this.hasUnsavedChanges) {
        this.$store.dispatch('output/clearOutputHasChanged');
      }
      const returnToClientProposals = this.$route.query?.clientProposals;
      if (returnToClientProposals) {
        return this.$router.push({ name: Routes.Clients });
      }
      if (window.history.length > 2) {
        return this.$router.go(-1);
      }
      this.$router.push({ name: Routes.Home });
    },
    setLocalOutput(): void {
      this.$store.dispatch('output/cloneOutput');
    },
    getAvailableLayouts({ agency }: { agency?: string }): void {
      this.$store.dispatch('output/getAvailableLayouts', { agency });
    },
    async getOutputSlides(proposalId): Promise<void> {
      await this.$store.dispatch('output/getOutput', proposalId);
    },
    changeAgency(agencyObject: PitchAgency): void {
      const { PropertyId: agency } = agencyObject;
      if (agency === this.agencyPropertyId) return;
      if (this.$route.name === 'manageProductSlides') {
        this.$router.replace({
          params: { agency },
          query: { productId: this.productId },
        });
      }
      this.selectedAgency = agencyObject;
    },
    async updateOutputLayout(payload: { layout: string; agencyId: string }) {
      const canProceed = await this.checkChanges();

      if (canProceed) {
        this.$store.dispatch('output/clearOutputHasChanged');
        await this.$store.dispatch('output/updateProposalLayout', { ...payload, proposalId: this.proposalId });
      }
    },
    async checkChanges(): Promise<boolean> {
      let canProceed = true;

      if (this.hasUnsavedChanges) {
        canProceed = (
          await this.$confirm.show({
            title: 'Discard unsaved changes?',
            body: 'Are you sure you want to update layout without saving your changes?',
            confirmText: 'Discard',
            cancelText: 'Cancel',
          })
        ).confirmed;
      }

      return canProceed;
    },
  },

  async mounted(): Promise<void> {
    await this.$store.dispatch('newProposal/setInProgressProposal', {
      proposalId: this.proposalId,
      forceUpdate: true,
    });
    // this.$store.dispatch('output/getLibrarySlides', { agencyId: this.activeProposal.agencyId });
    this.getAvailableLayouts({ agency: this.activeProposal.agencyId });
    await this.getOutputSlides(this.proposalId);
  },

  async beforeRouteLeave(to, from, next): Promise<void> {
    this.$store.dispatch('output/resetOutputAndProposal', {
      routeName: to.name,
    });
    next();
  },
});
